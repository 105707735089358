import {NextPage} from 'next';
import * as Sentry from '@sentry/nextjs';
import {useEffect} from 'react';

import Layout from '../../components/Layout';
import PlanCard from '../../components/PlanCard';
import Results from '../../components/Results';
import Filters from '../../components/filters/Filters';
import {
  GetPlanOffersDocument,
  PlanResult,
  PlanSearch,
  ProductOffer,
  PackageOffer,
  PlanOffer,
  FeaturedDeal,
} from '../../generated/graphql';
import paramsToFilters from '../../utils/paramsToFilters';
import useMenuToggle from '../../hooks/useMenuToggle';
import useSearch from '../../hooks/useSearch';
import {SelectedFilters} from '../../types/SelectedFilters';
import {BaseFilter, FilterOption} from '../../advisor-config/utils';
import {planConfig} from '../../advisor-config/plan/plan';
import {
  getFeaturedDeals,
  getOffers,
} from '../../utils/queries';
import {registerInteraction, registerView} from '../../utils/tracking';
import {setCacheControlHeader} from '../../utils/setCacheControlHeader';
import ProductHeader from '../../components/ProductHeader';

type Offer = ProductOffer | PackageOffer | PlanOffer;

interface PlanPageProps {
  initialResults: PlanSearch;
  initialFilters: SelectedFilters;
  defaultFilters: SelectedFilters;
  platformName: string;
  featuredDeals: FeaturedDeal[];
}

const Plan: NextPage<PlanPageProps> = ({
  initialResults,
  initialFilters,
  defaultFilters,
  platformName,
  featuredDeals,
}) => {
  const {isMenuOpen, toggleMenu} = useMenuToggle({
    onToggle: () => registerInteraction('toggle-filter', 'Sim Only', 'Sim Only'),
  });

  const queryDocument = GetPlanOffersDocument;
  const filterConfig = planConfig;

  const {
    filters,
    setFilterOption,
    selectedFilters,
    loading,
    results,
    showMoreResults,
    first,
    resetFilters,
    hasNonFeaturedFilters,
    hasFeaturedFilters,
  } = useSearch(queryDocument, filterConfig, initialResults, initialFilters, platformName, defaultFilters);

  useEffect(() => {
    registerView('vergelijker-simonly', 'Sim Only');
  }, [results]);

  const setFilterOptionAndRegisterInteraction = (filter: BaseFilter, option: FilterOption) => {
    setFilterOption(filter, option);
    registerInteraction(`${filter.label}-${option.value}`, 'vergelijker-simonly-filters', 'Sim Only');
  };

  return (
    <Layout
      platformName={platformName}
      Header={
        <ProductHeader
          filters={filters}
          setFilterOption={setFilterOptionAndRegisterInteraction}
          selectedFilters={selectedFilters}
          toggleMenu={toggleMenu}
          hasNonFeaturedFilters={hasNonFeaturedFilters}
          hasFeaturedFilters={hasFeaturedFilters}
        />
      }
      Filters={
        <Filters
          filters={filters}
          setFilterOption={setFilterOptionAndRegisterInteraction}
          selectedFilters={selectedFilters}
          toggleMenu={toggleMenu}
          isMenuOpen={isMenuOpen}
          resetFilters={resetFilters}
          hasNonFeaturedFilters={hasNonFeaturedFilters}
          hasFeaturedFilters={hasFeaturedFilters}
        />
      }
      Results={
        <Results
          results={results.results as PlanResult}
          ResultCard={(offer: Offer, index: number) =>
            <PlanCard
              offer={offer as PlanOffer}
              position={index + 1}
            />
          }
          featuredDeals={featuredDeals}
          showMoreResults={showMoreResults}
          first={first}
          resetFilters={resetFilters}
          loading={loading}
          hasNonFeaturedFilters={hasNonFeaturedFilters}
        />
      }
    />
  );
};

export async function getServerSideProps({query, res}: {query: any; res: any;}) {
  Sentry.setContext('advisor-page', {
    pageUrl: query.pageUrl,
    platform: query.platformName,
  });
  const platformId = parseInt(query.platformId || '1', 10);
  const platformName: string = query.platformName?.toUpperCase() || 'ANDROIDPLANET';
  const params = JSON.parse(query.params || '{"telcoName":[]}');
  const defaults = JSON.parse(query.defaults || '{"telcoName":[]}');

  const {filters, initialFilters} = paramsToFilters(params, planConfig.filters);
  const {initialFilters: defaultFilters} = paramsToFilters(defaults, planConfig.filters);

  const [offers, featuredDeals] = await Promise.all([
    getOffers(GetPlanOffersDocument, platformName, filters),
    getFeaturedDeals(platformId, 'plan_offer'),
  ]);

  setCacheControlHeader(res);

  return {
    props: {
      initialResults: offers.data.search,
      initialFilters,
      defaultFilters,
      platformName,
      featuredDeals: featuredDeals.data.featuredDeals,
    },
  };
}

export default Plan;
