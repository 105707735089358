import {PackageDiscount, Plan, PlanOffer} from '../generated/graphql';
import {registerInteraction} from '../utils/tracking';

import Euro from './Euro';
import Image from './Image';
import OfferLink from './OfferLink';
import OfferInfo from './offerInfo/OfferInfo';
import PlanSpecs from './PlanSpecs';
import PackageDiscountLabel from './PackageDiscountLabel';

interface PlanCardProps {
  offer: PlanOffer;
  position: number;
}

export default function PlanCard({
  offer,
  position,
}: PlanCardProps) {
  if (!offer?.shop?.id) {
    return null;
  }

  return (
    <div className="sf-border sf-border-gray-300 sf-bg-white sf-rounded-md">
      <PackageDiscountLabel
        packageDiscount={offer.packageDiscount as PackageDiscount}
      />
      <div className="sf-p-4 sf-grid sf-grid-cols-2 sf-grid-rows-2 md:sf-grid-cols-3">
        <div className="sf-flex sf-flex-col sf-row-span-2 sf-justify-between">
          {offer.planRenewal &&
            <div className="sf-w-fit sf-mb-2 sf-py-1 sf-px-2 sf-rounded-full sf-bg-primary sf-text-white sf-text-xs sf-font-bold sf-text-center">
              Verlengen
            </div>
          }
          <div className="sf-font-bold sf-text-lg">{offer.telco?.name} sim only</div>
          <PlanSpecs
            plan={offer.plan as Plan}
          />
        </div>
        {offer.telco &&
          <Image
            className="sf-h-12 sf-max-w-[8rem] sf-max-h-[4rem] sf-col-start-2 md:sf-col-start-3 sf-self-start sf-justify-self-end"
            src={offer.telco.imageUrl}
            alt={offer.telco.name}
            imgSet={[
              '{imgurl}?w=142&h=50&fit=contain 1x',
              '{imgurl}?w=284&h=100&fit=contain 2x',
            ]}
          />
        }
        <div className="sf-col-start-2 md:sf-row-span-2 sf-text-right md:sf-text-center">
          <span className="sf-text-2xl sf-font-bold">
            <Euro amount={offer.planPrice as number} />
          </span>
          <span className=""> p/m</span>
          <div className="sf-text-gray-400 sf-font-light">
            Eenmalig: <Euro amount={offer.planContractSetupFee as number} />
          </div>
        </div>
        <div className="sf-col-span-full md:sf-col-span-1 sf-mt-2 md:sf-m-t-0">
          <OfferLink
            href={offer.deeplink || ''}
            onClick={() => {
              const label = `${offer.telco?.name}|${offer.planPrice}|${offer.plan?.callLimit}min|${offer.plan?.dataLimit}mb|${offer.plan?.contractDuration}mnd|${offer.packageDiscount?.name || 'Geen'}|${offer.shop?.name}|${offer.suboffers?.length}deals|p${position}`;
              registerInteraction(offer.shop?.name || '', 'vergelijker-simonly-uitklik', label);
            }}
            className="sf-block sf-self-end sf-justify-self-end sf-rounded sf-text-white sf-text-center sf-font-bold sf-text-md sf-bg-primary hover:sf-bg-primary/80 sf-px-8 sf-py-4 sf-leading-tight md:sf-ml-4"
            queryParams={{
              position,
              originalDeeplink: offer.originalDeeplink || '',
              zone: 'vergelijker',
              shopLogoUrl: offer.shop?.imageUrl || '',
            }}
          >
            Bekijk bij {offer.shop?.name}
          </OfferLink>
        </div>
      </div>
      <div className="sf-block">
        {offer.promotions.length > 0 &&
          <ul className="sf-text-xs sf-text-green-700 sf-pb-2">
            {offer.promotions.map((promotion, i) =>
              <li className="sf-pb-1 sf-pl-4" key={`${promotion.fullText}_${offer.id}`}>
                ✓ {promotion.fullText}
              </li>)}
          </ul>
        }
      </div>
      <OfferInfo
        offer={offer}
        position={position}
      />
    </div>
  );
}
