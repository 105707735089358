import {gql} from '@apollo/client';

import {
  createDataLimitFilter,
  createCallLimitFilter,
  createTelcoFilter,
  createPlanRenewalFilter,
  createContractDurationFilter,
  createPackageDiscountFilter,
  createPlanNetworkGenerationFilter,
  createTelcoEsimFilter,
  createDownloadSpeedFilter,
} from '../sharedFilters';

export const planConfig = {
  filters: [
    createDataLimitFilter(),
    createCallLimitFilter(),
    createTelcoFilter(),
    createPlanRenewalFilter(),
    createPlanNetworkGenerationFilter(),
    createPackageDiscountFilter(),
    createTelcoEsimFilter(),
    createContractDurationFilter(),
    createDownloadSpeedFilter(),
  ],
};

export const {definitions} = gql`
  fragment plansFacets on PlanFacets {
    telcoNameTerms {
      ...stringFilter
    }
    telcoEsimTerms {
      ...booleanFilter
    }
    planContractDurationTerms {
      ...integerFilter
    }
    planRenewalTerms {
      ...booleanFilter
    }
    packageDiscountNameTerms {
      ...stringFilter
    }
    planNetworkGenerationTerms {
      ...stringFilter
    }
  }
`;
